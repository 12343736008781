<template>
  <main class="bg-surface-lvl-00 min-h-screen flex flex-col items-center justify-center bg-sign-in bg-no-repeat bg-contain bg-right gap-2">
    <div class="max-w-[420px] w-full flex flex-1 flex-col justify-center gap-5 p-4 bg-transparent">
      <FormWizard
        :validation-schema="validationSchema"
        @submit="handleSubmit"
        ref="wizard"
        headerType="login"
        footerType="login"
        :submitButtonText="t('page.sign_in.sign_in')"
        :loading="loggingIn"
        class="[&_.form-content]:px-0"
      >
        <template #image>
          <Avatar
            size="sm"
            :entity="{
              name: 'HomeRunner',
              email: '',
              image: '/images/logo.png',
            }"
          />
        </template>
        <FormStep>
          <div>
            <h1 class="text-[22px] font-medium text-primary">{{ t("page.sign_in.title") }}</h1>
          </div>
          <div>
            <InputText :label="t('email')" id="email" name="email" type="email" autocomplete="email" required :placeholder="t('enter_your_email')" size="large">
            </InputText>
          </div>

          <div>
            <InputText
              :label="t('password')"
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              :placeholder="t('enter_your_password')"
              size="large"
            >
            </InputText>
          </div>
        </FormStep>

        <template #footer-buttons>
          <div class="flex flex-col gap-3">
            <div class="flex gap-1">
              <div class="text-body-default text-tertiary">
                {{ t("page.sign_in.dont_have_account") }}
              </div>
              <Button variant="primary-link" :to="{ name: 'auth-register' }"> {{ t("page.sign_in.create_user") }} </Button>
            </div>

            <div class="flex gap-1">
              <div class="text-body-default text-tertiary">
                {{ t("page.sign_in.forgot-your-password") }}
              </div>
              <Button variant="primary-link" :to="{ name: 'auth-reset-request' }"> {{ t("page.sign_in.reset_password") }} </Button>
            </div>
          </div>
        </template>
      </FormWizard>
      <!--
      <Divider class="w-full" :text="t('or')" />

      <div class="space-y-3">
        <Button class="w-full" variant="default" size="large">
          <template #prefix>
            <Icon size="large" src="services/google" filled />
          </template>
          {{ t("page.sign_in.continue_with", { service: "Google" }) }}
        </Button>
        <Button class="w-full" variant="default" size="large">
          <template #prefix>
            <Icon size="large" src="services/apple" filled />
          </template>
          {{ t("page.sign_in.continue_with", { service: "Apple" }) }}
        </Button>
        <div class="text-sm text-tertiary">
          {{ t("page.sign_in.dont_have_account") }}
          <NuxtLink :to="{ name: 'auth-register' }" class="font-medium text-brand hover:text-brand-dark"> {{ t("page.sign_in.sign_up") }} </NuxtLink>
        </div>
      </div>
      -->
    </div>
    <AuthFooter />
  </main>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
definePageMeta({
  layout: "auth",
});

const { t } = useI18n();
const wizard = ref(null);
const yup = useYup();

const validationSchema = [
  yup.object({
    email: yup.string().email().required().label(t("email")),
    password: yup.string().required().label(t("password")),
  }),
];

const loggingIn = ref(false);

const { login, setCapabilities, getCapabilities, clearCookies, setAuthCookie, updateCapabilities } = useAuth();

//if url has token and expires
if (process.client && window.location.search.includes("token") && window.location.search.includes("expires_in")) {
  loggingIn.value = true;
  const token = new URLSearchParams(window.location.search).get("token");
  const expires_in = new URLSearchParams(window.location.search).get("expires_in");
  const isFromExternal = new URLSearchParams(window.location.search).get("cs");
  const expires = parseInt(expires_in!);

  setAuthCookie(token!, expires);
  nextTick(() => {
    getCapabilities()
      .then(setCapabilities)
      .then(() => {
        if (isFromExternal)
          setCookie({
            name: "external_access",
            value: "true",
          });
      })
      .catch((error) => {
        clearCookies();
        $toast.add({
          title: t("login_failed"),
          text: t("login_failed_try_again"),
        });
        loggingIn.value = false;
      });
  });
}

const handleSubmit = (formData) => {
  removeCookie("external_access");

  loggingIn.value = true;
  login(
    {
      username: formData.email,
      password: formData.password,
    },
    true
  )
    .then(getCapabilities)
    .then(setCapabilities)
    .catch((error) => {
      clearCookies();
      $toast.add({
        title: t("login_failed"),
        text: t("login_failed_try_again"),
      });
      loggingIn.value = false;
    });
};
</script>
